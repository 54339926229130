<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <!-- <el-form-item label="商品名称" prop="spuName">
                <el-input v-model="query.spuName" placeholder="请输入商品名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="商品分类" prop="spuType" >
                <el-select v-model="query.spuType" placeholder="请选择商品类型" clearable size="small">
                    <el-option v-for="dict in dictType" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item> -->
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="优惠卷名称" align="center" prop="couponName" />
            <el-table-column label="减扣类型" align="center" prop="reduceType">
                <template slot-scope="scope">
                    <el-tag type="success">{{ scope.row.reduceType == '1' ? '金额' : '折扣' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="打折力度" align="center" prop="discount" >
                <template #default="scope">
                    {{ scope.row.reduceType == 1 ?0.00:scope.row.discount }}
                </template>
            </el-table-column>
            <el-table-column label="抵扣金额" align="center" prop="amount">
                <template slot-scope="scope">
                    <span style="color: red">￥{{ scope.row.reduceType == 1?scope.row.amount.toFixed(2):0.00 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="用户昵称" align="center" prop="nickname" />
            <el-table-column label="手机号" align="center" prop="phone" />
            <el-table-column label="领取方式" align="center" prop="getType" >
                <template #default="scope">
                    <el-tag type="success">{{ scope.row.getType == '0' ? '后台赠送' : '主动领取' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="领取时间" align="center" prop="createTime" width="160"/>
            <el-table-column label="可使用时间" align="center" prop="startTime" >
                <template #default="scope">
                    {{  scope.row.startTime ? scope.row.startTime.split(' ')[0]:''  }}
                </template>
            </el-table-column>
            <el-table-column label="过期时间" align="center" prop="endTime" >
                <template #default="scope">
                    {{  scope.row.endTime ? scope.row.endTime.split(' ')[0]:''  }}
                </template>
            </el-table-column>
            <el-table-column label="使用时间" align="center" prop="useTime" />
            <el-table-column label="使用单号" align="center" prop="orderSn" />
            <el-table-column label="使用状态" align="center" prop="useType" >
                <template #default="scope">
                    <el-tag :type="scope.row.useType == '0' ? 'success' : (scope.row.useType == 1 ? 'info' : 'info')">{{ scope.row.useType == '0' ? '未使用' : (scope.row.useType == 1 ? '已使用' : '已过期') }}</el-tag>
                </template>
            </el-table-column>

        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

        <!-- 详情 -->
        <!-- 编辑弹窗 -->
        <el-dialog title="提现详情" :visible.sync="dialogVisible" width="60%" >
            <el-table  :data="itemList" >
                <el-table-column label="订单号" align="center" prop="orderId" />
                <el-table-column label="订单金额" align="center" prop="orderMoney" >
                    <template #default="scope">
                        <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.orderMoney).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="收益金额" align="center" prop="saleMoney" >
                    <template #default="scope">
                        <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.saleMoney).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="收益时间" align="center" prop="createTime" />
            </el-table>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'Extract',
    data() {
        return {
            // 列表
            list: [],
            itemList: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 弹窗控制器
            dialogVisible: false,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.historyList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query.current = 1
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        // 查看提现明细
        handleView(data) {
            this.dialogVisible = true
        }
    }
}

</script>